const jQuery = require('jquery');

import GoogleMapsHelper from '../helpers/google_maps';

window.initializeGoogleMaps = function () {
  if (jQuery('#map-canvas'.length)) {
    if (!jQuery('#map-canvas').is(":visible")) return;

    GoogleMapsHelper.codeAddress(jQuery('#map-canvas'));
  }
};

jQuery(document).on('turbolinks:load', function () {
  jQuery(document).on('click', '#map-canvas', function () {
    GoogleMapsHelper.redirectToMap(jQuery(this));
  });
});
