const jQuery = require('jquery');

import dt from "datatables.net";
import GoogleMapsHelper from '../helpers/google_maps';

jQuery(document).on('turbolinks:load', function () {
  dt(window, $);

  const table = jQuery('#companies-datatable').DataTable({
    "processing": true,
    "serverSide": true,
    "responsive": true,
    "ajax": {
      "url": jQuery('#companies-datatable').data('source')
    },
    "pageLength": 25,
    "bLengthChange": false,
    "bFilter": true,
    "bInfo": false,
    "pagingType": "simple_numbers",
    "dom": '<l<t>ip>',
    "columns": [
      {
        "data": "name", render: function (datum, type, row) {
          return "<strong class='block'>" + row.name + "</strong><span>" + row.address_details + "</span>";
        }
      },
      {
        "data": "trade_name", render: function (datum, type, row) {
          if (!row.trade_name) return "Ingen bransch angiven";

          return row.trade_name;
        }
      },
      {
        "data": "employee_size", render: function (datum, type, row) {
          if (!row.employee_size || row.employee_size == 0) return '';

          return row.employee_size;
        }
      },
      { "data": "workplace_name" },
      { "data": "searchwords" },
      { "data": "city" },
      { "data": "trade_id" },
      { "data": "keywords" }
    ],
    "columnDefs": [
      {
        "targets": [3],
        "visible": false,
        "searchable": true
      },
      {
        "targets": [4],
        "visible": false,
        "searchable": true
      },
      {
        "data": "city",
        "targets": [5],
        "visible": false,
        "searchable": true
      },
      {
        "data": "trade_id",
        "targets": [6],
        "visible": false,
        "searchable": true
      },
      {
        "data": "keywords",
        "targets": [7],
        "visible": false,
        "searchable": true
      },
      {
        "data": "municipality",
        "targets": [8],
        "visible": false,
        "searchable": true
      }
    ],
    "language": {
      "sEmptyTable": "<h5>Tabellen innehåller ingen data.</h5>",
      "sInfo": "Visar _START_ till _END_ av totalt _TOTAL_ rader",
      "sInfoEmpty": "Visar 0 till 0 av totalt 0 rader",
      "sInfoFiltered": "(filtrerade från totalt _MAX_ rader)",
      "sInfoPostFix": "",
      "sInfoThousands": " ",
      "sLengthMenu": "Visa _MENU_ rader",
      "sLoadingRecords": "Laddar...",
      "sProcessing": "Bearbetar...",
      "sSearch": "",
      "searchPlaceholder": "Företag eller verksamhet",
      "sZeroRecords": "<h5>Vi hittade tyvärr ingenting som matchar din sökning.</h5>",
      "oPaginate": {
        "sFirst": "",
        "sLast": "",
        "sNext": "<i class='fas fa-chevron-right'></i>",
        "sPrevious": "<i class='fas fa-chevron-left'></i>"
      },
      "oAria": {
        "sSortAscending": ": aktivera för att sortera kolumnen i stigande ordning",
        "sSortDescending": ": aktivera för att sortera kolumnen i fallande ordning"
      }
    },
    createdRow: function (row, data, index) {
      jQuery(row).addClass('company-row');
      jQuery(row).attr('data-company-id', data.id);
      jQuery(row).attr('data-company-path', data.path);
    }
  });

  jQuery('.search-box-holder button').on('click', function () {
    table.search(jQuery('.search-field').val()).draw();
  });

  jQuery('.search-field').on('change keyup', function () {
    if (jQuery(this).val() !== '') return;

    table.search('').draw();
  });

  jQuery('.dropdown-check-list .anchor').on('click', function (e) {
    e.stopPropagation();
    const $parent = jQuery(this).parent();

    if ($parent.hasClass('visible')) {
      $parent.removeClass('visible')
    } else {
      jQuery('.dropdown-check-list').removeClass('visible');
      $parent.addClass('visible')
    }
  });

  jQuery('.checkbox-trigger').on('click', function (e) {
    e.stopPropagation();
    const $activeOptions = jQuery(this).parent().parent().find('.active-options');
    const $availableOptions = jQuery(this).parent().parent().find('.available-options');
    const $checkList = $availableOptions.parent();
    const $textHolder = $checkList.parent().find('.anchor .text-holder');
    const $displayOption = $checkList.find("[data-filter-value='" + jQuery(this).attr('data-filter-value') + "']");
    const filterColumn = $checkList.parent().attr('data-filter-column')

    $displayOption.removeClass('hidden-option');

    if ($displayOption.find('.checkbox-holder').hasClass('active')) {
      $displayOption.find('.checkbox-holder').removeClass('active');
    } else {
      $displayOption.find('.checkbox-holder').addClass('active');
    }

    jQuery(this).addClass('hidden-option');
    jQuery(this).find('.checkbox-holder').removeClass('active');
    $textHolder.text(jQuery(this).attr('data-filter-value'));

    const selectedCount = $checkList.parent().find('.checkbox-holder.active').length;
    $activeOptions.removeClass('hidden');

    if (selectedCount == 0) {
      $activeOptions.addClass('hidden');
      $textHolder.text('Alla');
    } else if (selectedCount > 1) {
      $textHolder.text(selectedCount + ' valda');
    }

    $checkList.parent().find('.checkbox-holder.active input.inner-checkbox').attr('checked', 'checked')
    const values = $checkList.parent().find('.checkbox-holder.active .inner-checkbox:checked').map(function () {
      return this.value;
    }).get().join('|');

    table.column(filterColumn).search(values).draw();
  });

  jQuery('.clear-filters').on('click', function (e) {
    e.preventDefault();

    jQuery('.search-field').val('');
    jQuery('.anchor .text-holder').text('Alla');
    jQuery('.active-options .checkbox-trigger').addClass('hidden-option');
    jQuery('.available-options .checkbox-trigger').removeClass('hidden-option');
    jQuery('.checkbox-holder').removeClass('active');
    jQuery('.active-options').addClass('hidden');

    table.search('').columns().search('').draw();
  });

  jQuery('#companies-datatable tbody').on('click', 'td', function (e) {
    const target = jQuery(e.target);
    if (target.hasClass('follow-link') || target.is('div') || target.is('p') || target.is('h3')) return;

    const tr = jQuery(this).closest('tr');
    const row = table.row(tr);

    if (row.child.isShown()) {
      // Row is already open - close it
      row.child.hide();
      tr.removeClass('shown');
    }
    else {
      row.child(loadRowData(row.data()), 'row-details').show();
      tr.addClass('shown');

      setTimeout(function () {
        GoogleMapsHelper.codeAddress(jQuery('#map-canvas-' + row.data().id));
      }, 200);
    }
  });

  jQuery('body').on('click', function (e) {
    jQuery('.dropdown-check-list').removeClass('visible');
  });

  function loadRowData(data) {
    if (!data) return;

    const placeholder = '<div class="details"><h5>H&auml;mtar information ...</h5></div>';
    return jQuery(placeholder).load(location.origin + '/' + data.path);
  }

  jQuery(document).on('click', 'a.complement-details-link', function () {
    window.location = jQuery(this).attr('href');
  });

  jQuery(document).on('click', '.map-canvas', function () {
    return GoogleMapsHelper.redirectToMap(jQuery(this));
  });
});
