const jQuery = require('jquery');

const GoogleMapsHelper = {
  buildMap($element) {
    const latlng = new google.maps.LatLng(0, 0);
    const options = {
      zoom: 10,
      center: latlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true
    }
    return new google.maps.Map(document.getElementById($element.attr('id')), options);
  },
  buildGecoder() {
    return new google.maps.Geocoder();
  },
  codeAddress($element) {
    const map = GoogleMapsHelper.buildMap($element);
    const geocoder = GoogleMapsHelper.buildGecoder();

    // address = "275-291 Bedford Ave, Brooklyn, NY 11211, USA";
    geocoder.geocode({ 'address': $element.data('address'), 'region': 'se' }, function (results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        map.setCenter(results[0].geometry.location);
        const marker = new google.maps.Marker({
          map: map,
          position: results[0].geometry.location
        });

        $element.attr('data-latlng', results[0].geometry.location);
      }
    });
  },
  redirectToMap($element) {
    const query = encodeURIComponent($element.data('latlng'));
    if (!query) return;

    window.open('http://maps.google.com/maps?q=' + query, '_blank');
  }
};

export default GoogleMapsHelper;
